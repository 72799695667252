import { Link, navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import { FirebaseContext } from "../../components/Firebase";
import Seo from "../../components/seo";
import { ContentBg, ContentBox, Select } from "../../components/ui";

const StandingsHeader = styled.div`
  Select {
    width: 100%;
    margin-bottom: 1rem;
    &:nth-child(2) {
      @media screen and (min-width: 768px) {
        width: auto;
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
    }
  }
`;

const StandingsList = styled.ol`
  li {
    margin-left: 1rem;
    padding: 0.2rem 0.3rem;
    border-bottom: 1px dotted #ccc;
    &:hover {
      background: #ddd;
    }
    &.self {
      background: orange;
      a {
        color: white;
      }
    }
    div {
      display: flex;
      justify-content: space-between;
      span {
        white-space: nowrap;
      }
    }
    span {
      display: inline-block;
      color: #555;
    }
  }
`;

const StandingsPage = () => {
  const [profiles, setProfiles] = useState([]);
  const [standings, setStandings] = useState(null);
  const [appInfo, setAppInfo] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [league, setLeague] = useState("");
  const [schedule, setSchedule] = useState("");
  const { firebase, user } = useContext(FirebaseContext);
  useEffect(() => {
    if (!firebase) {
      navigate("/");
    } else {
      firebase
        .getSchedule()
        .then(schedule => setSchedule(schedule))
        .then(async () => {
          const result = await firebase.getLastUpdated();
          setAppInfo(result);
          setSelectedMatch(result.lastMatchUpdated);
          const profiles = await firebase.getPublicProfiles();
          setProfiles(profiles);

          const standings = await firebase.getStandings(
            result.lastMatchUpdated
          );
          setStandings(standings);
        });
    }
  }, []);

  const getStandingsForMatch = matchNumber => {
    firebase.getStandings(matchNumber).then(standings => {
      setStandings(standings || null);
    });
  };

  const standingsByValue = [];
  if (standings) {
    Object.entries(standings).forEach(entry => {
      if (!standingsByValue[entry[1]]) {
        standingsByValue[entry[1]] = [];
      }
      standingsByValue[entry[1]].push(entry[0]);
    });
  }
  const standingsOrdered = standingsByValue;

  const intl = useIntl();

  return (
    <ContentBg src="/static/c41c656219d120e7127cf400171a9d84/4c91a/players.png">
      <ContentBox>
        <Seo title="World Cup Poule 2022" />
        <h1>
          <FormattedMessage id="wc2022_standings" />
        </h1>
        {selectedMatch ? (
          // (user && user.userId === process.env.GATSBY_FIREBASE_ADMIN_UID) ? (
          <>
            <StandingsHeader>
              <FormattedMessage id="wc2022_updated_up_to" />
              <Select
                defaultValue={selectedMatch}
                onChange={e => {
                  setSelectedMatch(e.target.value);
                  getStandingsForMatch(e.target.value);
                }}
              >
                {schedule.map(match => {
                  return match.MatchNumber < 65 ? (
                    <option
                      key={match.MatchNumber}
                      value={match.MatchNumber}
                      disabled={!match.CorrectAnswer}
                    >
                      {`#${match.MatchNumber} - `}
                      {match.CorrectHomeCountry
                        ? intl.formatMessage({
                            id: match.CorrectHomeCountry,
                          })
                        : intl.formatMessage({
                            id: `wc2022_${
                              match.HomeTeam.toLowerCase().split(" ")[0]
                            }`,
                          })}
                      {intl.formatMessage({
                        id: "wc2022_vs",
                      })}
                      {match.CorrectAwayCountry
                        ? intl.formatMessage({
                            id: match.CorrectAwayCountry,
                          })
                        : intl.formatMessage({
                            id: `wc2022_${
                              match.AwayTeam.toLowerCase().split(" ")[0]
                            }`,
                          })}
                    </option>
                  ) : (
                    <option
                      key={match.MatchNumber}
                      value={match.MatchNumber}
                      disabled={!match.CorrectAnswer}
                    >
                      {intl.formatMessage({
                        id: "wc2022_round8",
                      })}
                    </option>
                  );
                })}
              </Select>
              <Select
                onChange={e => setLeague(e.target.value)}
                defaultValue={league}
              >
                <optgroup label="Sub-leagues">
                  <option key="foo" value="">
                    {intl.formatMessage({
                      id: "wc2022_everyone",
                    })}
                  </option>
                  <option key="family" value="family">
                    Kater & Co 😺
                  </option>
                  <option key="eero" value="eero">
                    eero 🖱️
                  </option>
                  <option key="hc" value="hc">
                    Holland Casino 🎰
                  </option>
                  <option key="jos" value="jos">
                    Langedijkers (and honorary Langedijkers) 💰
                  </option>
                </optgroup>
              </Select>
            </StandingsHeader>
            {standings ? (
              <StandingsList>
                {Object.keys(standingsByValue)
                  .reverse()
                  .map(points => {
                    const ordered = standingsByValue[points].sort();
                    return ordered.map(email => {
                      const profile = profiles.find(p => p.id === email);
                      if (
                        (league.length &&
                          profile &&
                          profile.userId &&
                          profile.league &&
                          profile.league.split(",").includes(league)) ||
                        (!league && profile)
                      ) {
                        return (
                          <li
                            key={profile.userId}
                            className={
                              user.userId === profile.userId ? "self" : ""
                            }
                          >
                            <div>
                              <Link
                                to={
                                  user.userId === profile.userId
                                    ? "/worldcup2022/schedule"
                                    : `/worldcup2022/schedule?${profile.userId}`
                                }
                              >
                                {profile.name}
                              </Link>
                              <span>
                                {" "}
                                {points} <FormattedMessage id="wc2022_points" />
                              </span>
                            </div>
                          </li>
                        );
                      }
                    });
                  })}
              </StandingsList>
            ) : (
              <>
                <h4>This match hasn't been played (or updated) yet.</h4>
              </>
            )}
          </>
        ) : (
          <>
            <h3>Participants</h3>
            <StandingsHeader>
              <Select
                onChange={e => setLeague(e.target.value)}
                defaultValue={league}
              >
                <optgroup label="Sub-leagues">
                  <option key="foo" value="">
                    {intl.formatMessage({
                      id: "wc2022_everyone",
                    })}
                  </option>
                  <option key="family" value="family">
                    Kater & Co 😺
                  </option>
                  <option key="eero" value="eero">
                    eero 🖱️
                  </option>
                  <option key="hc" value="hc">
                    Holland Casino 🎰
                  </option>
                  <option key="jos" value="jos">
                    Langedijkers (and honorary Langedijkers) 💰
                  </option>
                  <option key="unknown" value="unknown">
                    People who Mark doesn't know 🤷🏻‍♂️
                  </option>
                </optgroup>
              </Select>
            </StandingsHeader>
            <StandingsList>
              {profiles.map(profile => {
                if (
                  (league.length &&
                    profile.league &&
                    profile.league.split(",").includes(league)) ||
                  !league
                ) {
                  return (
                    <li key={profile.email}>
                      {profile.name || profile.email}
                      {" - "}
                      {profile.percentagePredicted &&
                      parseInt(profile.percentagePredicted, 10) === 100 ? (
                        <span>✅</span>
                      ) : profile.percentagePredicted ? (
                        <span>
                          {`${profile.percentagePredicted}`}
                          <FormattedMessage id="wc2022_remindthem" />
                        </span>
                      ) : (
                        <span>
                          <FormattedMessage id="wc2022_hasntstarted" />
                        </span>
                      )}
                    </li>
                  );
                } else if (league === "unknown" && !profile.league) {
                  return <li key={profile.email}>{profile.email}</li>;
                }
              })}
            </StandingsList>
          </>
        )}
      </ContentBox>
    </ContentBg>
  );
};

export default StandingsPage;
